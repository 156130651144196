import React from 'react';
import MaskedInput from 'antd-mask-input'
import { Result, Tooltip, Form, Input, Button, Divider, Row, Col, Select, Typography } from 'antd';
import { InfoCircleOutlined, WalletOutlined } from '@ant-design/icons';

const { Text } = Typography
const { Option } = Select;
const Global = props => {
    const [form1] = Form.useForm();

    //* Отправка формы
    async function licensesend(v) {
        v['method'] = 'license_upd';
        await props.api(v,true);
    }

    return (
        <React.Fragment>
            {props.state.success ? (
                <Result className="wowload"
                    status="success"
                    title="Сообщение добавлено в очередь отправки"
                    subTitle="SMS занесено в список отправленных" />
            ) : (
                <Row style={{flexDirection:'column',justifyContent:'center'}} className="column900 wowload">
                    <Col key="k3_add" className="shadowscreen" >
                        <Form name="form" form={form1} onFinish={licensesend} className="form" >
                            <div className="header_title">Активация лицензии</div>
                            <div style={{padding:20}} >
                                <Form.Item className="max500" name="key" label="Ключ" rules={[{ required: true, message: 'Пожалуйста, укажите лицензионный ключ!' }]}>
                                    <Input />
                                </Form.Item>
                                <div className="fcenter">
                                    <Form.Item>
                                        <Button className="des-button" type="dashed" shape="round" htmlType="submit">Активировать</Button>
                                    </Form.Item>
                                </div>
                            </div>
                            
                        </Form>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    )
};

export default Global;